<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	export default {
		extends: Base,
		data() {
			return {
				Name: "BoDashboard",
				PageName: 'Dashboard',
				site_name: "",
				last_activity_request: {},
				data_remaining: {},
				api: {},
				ykey: [],
				color: [],
				mrClient: {},
				clients: [{}],
				api_activity: [{}],
				resume: {},
				data_chart: [],
			}
		},
		computed: {
			ufullname() {
				return this.user ? this.user.bu_full_name : "-"
			}
		},
		async mounted() {
			await Gen.loadScript("/backend/assets/node_modules/raphael/raphael-min.js")
			await Gen.loadScript("/backend/assets/node_modules/morrisjs/morris.min.js")
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			initChart() {
				setTimeout(() => {
					this.destroyChart()
					this.data_chart.forEach(v => {
						Morris.Area({
							element: v.id,
							data: v.data,
							xkey: 'period',
							ykeys: this.ykey,
							labels: this.ykey,
							pointSize: 3,
							fillOpacity: 0,
							pointStrokeColors: this.color,
							behaveLikeLine: true,
							gridLineColor: '#e0e0e0',
							lineWidth: 3,
							hideHover: 'auto',
							lineColors: this.color,
							resize: true
						});
					});
				}, 200);
			},
			destroyChart() {
				this.data_chart.forEach((v) => {
					$("#" + v.id).empty();
				})
			},
			search() {
				var query = Object.assign(Gen.clone(this.$route.query), Gen.clone(this.filter))
				delete query.page
				this.$router.push({
					name: this.Name,
					query: query
				})
			},
			refreshData(cb, param = {}) {
				this.data.data = false
				var params = Object.assign(param, this.$route.query)
				this.ajaxRefreshData = BOGen.apirest("/" + this.Name + (this.$route.params.id ? "/" + this.$route.params.id :
					""), params, (err, resp) => {
						if (err) return;
						Object.keys(resp).forEach((k) => {
							this[k] = resp[k]
						})
						if (this.$route.params.id) this.row.type = this.$route.params.id == "add" ? "add" : "update"
						this.initChart()
						if (this.onRefreshPage) return this.onRefreshPage(resp)
						if (cb) cb(resp)
					})
			},
		},
		watch: {
			async '$route.query'() {
				this.refreshData()
			},
			'filter.type'() {
				this.search()
			},
			async 'filter.show'(v) {
				if (!v) return
				await this.search()
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row">
			<div class="col-md-9">
				<div class="card-group">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<div class="d-flex no-block align-items-center">
										<div>
											<h3><i class="icon-screen-desktop"></i></h3>
											<p class="text-muted">CLIENTS</p>
										</div>
										<div class="ml-auto">
											<h2 class="counter text-primary">{{resume.ard_total_client}}</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Column -->
					<!-- Column -->
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<div class="d-flex no-block align-items-center">
										<div>
											<h3><i class="icon-settings"></i></h3>
											<p class="text-muted">ACTIVE API</p>
										</div>
										<div class="ml-auto">
											<h2 class="counter text-cyan">{{resume.ard_total_api}}</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Column -->
					<!-- Column -->
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<div class="d-flex no-block align-items-center">
										<div>
											<h3><i class="icon-user"></i></h3>
											<p class="text-muted">ACTIVE USERS</p>
										</div>
										<div class="ml-auto">
											<h2 class="counter text-purple">{{resume.ard_total_user}}</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-3" v-if="page.menuRole('AppRequest')">
				<router-link :to="{name:'AppRequest'}" class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-md-12">
								<div class="d-flex no-block align-items-center">
									<div>
										<h3>Report</h3>
										<p class="text-muted">SEE ALL REPORT <i class="icon-arrow-right"></i></p>
									</div>
									<div class="ml-auto">
										<h2 class="counter text-primary"><i class="icon-doc"></i></h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</router-link>
			</div>
			<!-- ============================================================== -->
			<!-- charts -->
			<!-- ============================================================== -->
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row align-items-center">
								<div class="col-md-2">
									<h5 class="card-title mb-0">Filter</h5>
								</div>

								<div class="col-md-4"></div>
								<div class="col-md-3">
									<select2 name="" v-model="filter.show">
										<option :value="7">7 Hari Terakhir</option>
										<option :value="14">14 Hari Terakhir</option>
										<option :value="30">30 Hari Terakhir</option>
									</select2>
								</div>
								<div class="col-md-3">
									<select2 name="" v-model="filter.type" :options="mrClient" :object="['mc_id','mc_name']">
										<option value="">-- Tampilkan Hanya --</option>
									</select2>
								</div>
							</div>
						</VForm>
					</div>
				</div>

				<div class="card" v-for="(v,k) in data_chart" :key="k">
					<div class="card-body">
						<div class="d-flex m-b-40 no-block">
							<div class="title">
								<h5 class="card-title ">{{v.api}}</h5>
								<ul class="list-inline font-12">
									<li v-for="(v2,k2) in clients" :key="k2"><i class="fa fa-circle" :style="'color:'+v2.color"></i>
										{{v2.name}}</li>
								</ul>
							</div>
						</div>

						<div :id="v.id" style="height: 340px;"></div>
					</div>
				</div>
			</div>

			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="no-block">
							<div class="title">
								<h5 class="card-title ">Latest Activity Hit</h5>
							</div>
						</div>
					</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<th>Date</th>
								<th>Client</th>
								<th>API</th>
								<!-- <th>Body</th> -->
								<th>Status</th>
							</thead>
							<tbody>
								<tr v-for="(v,k) in last_activity_request" :key="k">
									<td>{{(v.ar_create_at||"").dates("format2")}}</td>
									<td>{{v.mc_name}}</td>
									<td>{{v.ma_name}}</td>
									<!-- <td>--</td> -->
									<td>{{v.ar_response_code}}</td>
									<!-- <td><span class="badge badge-success">Success</span></td> -->
								</tr>
								<tr v-if="!last_activity_request.length">
									<td colspan="99">
										<h3 class="tc">Last Activity not found</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="no-block">
							<div class="title">
								<h5 class="card-title ">Remaining Quota</h5>
							</div>
						</div>
					</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<th>#</th>
								<th>Client</th>
								<th v-for="(v,k) in api" :key="k">{{v.ma_name}}</th>
								<th>Action</th>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data_remaining" :key="k">
									<td>{{k+1}}</td>
									<td>{{v.client}}</td>
									<td v-for="(v2,k2) in v.api" :key="k2">{{v2}}</td>
									<td>
										<router-link :to="{name:'AppManagementQuota',query:{search:v.client}}" href="javascript:;"
											class="icon_action"><i class="icon-eye"></i></router-link>
									</td>
								</tr>
								<tr v-if="!data_remaining.length">
									<td colspan="99">
										<h3 class="tc">Remaining Quota not found</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>